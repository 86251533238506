import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";

const PrivacyPolicy = () => {
  return (
    <>
      <SEO title="Privacy Policy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Privacy Policy" page="Privacy Policy" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <div className="section-title">
                    <h5 className="title">This Privacy policy was published on July 22nd, 2024.</h5>
                  </div>
                  <h4>Introduction</h4>
                  <p>
                    Welcome to my portfolio website. Your privacy is important to us, and this
                    policy explains how we collect, use, and protect your information.
                  </p>
                  <h4>Information We Collect </h4>
                  <p>
                    If you contact us, we may collect your name, email address, and any other
                    information you provide. We may collect non-personal information such as your
                    browser type, access times, and the pages you visit on our site.
                  </p>
                  <h4>How We Use Your Information</h4>
                  <p>
                    We use your personal information to respond to your questions or comments.
                    Non-personal information helps us improve our website's performance and user
                    experience.
                  </p>
                  <h4>Information Sharing</h4>
                  <p className="mb--20">
                    We do not sell, trade, or otherwise transfer your personal information to
                    outside parties.
                  </p>

                  <h4>Security</h4>
                  <p>
                    We implement a variety of security measures to maintain the safety of your
                    personal information.
                  </p>
                  <h4>Your Consent</h4>
                  <p>By using our site, you consent to our privacy policy.</p>
                  <h4>Changes to Our Privacy Policy</h4>
                  <p className="mb--20">
                    We may update our privacy policy from time to time. We will post any changes on
                    this page.
                  </p>

                  <h4>How long we keep your data</h4>
                  <p className="mb--20">
                    We store personal data for as long as we find it necessary to fulfill the
                    purpose for which the personal data was collected, while also considering our
                    need to answer your queries or resolve possible problems. This helps us to
                    comply with legal requirements under applicable laws, to attend to any legal
                    claims/complaints, and for safeguarding purposes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default PrivacyPolicy;
