import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import PortableText from "react-portable-text";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}
const WidgetPost = () => {
  // sanity ----
  const [postData, setPostData] = useState([]);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="post"]{
        title,
        slug,
        content,
        mainImage{
                asset->{
                    _id,
                    url
                }
            },
        "name": author->name,
        "authorImage": author->image,
        publishedAt
    }`
      )
      .then((data) => {
        setPostData(data);
      })
      .catch(console.error);
  }, []);
  // sanity ends ----
  // date and time -----------
  let [date, time] = [];
  postData.map((e) => {
    [date, time] = e.publishedAt.split("T");
  });
  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }
  // change timezone
  const chnz = (s) => {
    return new Date(s).toLocaleString(undefined, {
      timeZone: "Asia/Kolkata",
    });
  };
  console.log(chnz("2017-02-12T06:30:00Z"));
  console.log(postData.map((d) => d.publishedAt));
  return (
    <div className="post-list-wrap">
      {postData.slice(0, 3).map((data, index) => (
        <div className="single-post" key={index}>
          <div className="post-thumbnail">
            <Link to={process.env.PUBLIC_URL + "/blogpost/" + data.slug.current}>
              <img src={urlFor(data.mainImage.asset.url).url()} alt="Blog" />
            </Link>
          </div>
          <div className="post-content">
            <h6 className="title">
              <Link to={process.env.PUBLIC_URL + "/blogpost/" + data.slug.current}>
                {data.title}
              </Link>
            </h6>
            <ul className="blog-meta list-unstyled">
              <li>{data.publishedAt.slice(0, 10)}</li>
              <li>{chnz(data.publishedAt).slice(9, 26)}</li>
            </ul>
            {/* <p
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                webkitLineClamp: "2",
                webkitBoxOrient: "vertical",
              }}
            >
              <PortableText
                content={data.content}
                projectId="u91eo98w"
                dataset="production"
                serializers={{
                  h1: (props) => <h1 style={{ color: "red" }} {...props} />,
                  li: ({ children }) => <li className="special-list-item">{children}</li>,
                }}
              />
            </p> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default WidgetPost;
