import React from "react";
import FormOne from "../contact/FormOne";
import { motion } from "framer-motion";
// import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
const AboutOne = () => {
  return (
    <section className="section section-padding-equal ">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-us">
              <div className="section-heading heading-left mb-0">
                <motion.span
                  animate={{ opacity: 1, transition: { duration: 0.5, delay: 0.4 } }}
                  initial={{ opacity: 0 }}
                  className="subtitle"
                >
                  About Me
                </motion.span>
                <motion.h2
                  animate={{ opacity: 1, transition: { duration: 1.2, delay: 0.7 } }}
                  initial={{ opacity: 0 }}
                  className="title mb--40"
                >
                  I do design, code &amp; develop.
                </motion.h2>
                <motion.p
                  animate={{ opacity: 1, transition: { duration: 1.5, delay: 0.7 } }}
                  initial={{ opacity: 0 }}
                >
                  Welcome to my portfolio website! My services include web design and development,
                  graphic design, digital marketing, and branding. I have a wealth of experience in
                  these areas, and I have worked with clients in a variety of industries. I pride
                  myself on my ability to understand the needs and goals of my clients, and I strive
                  to create solutions that meet their unique requirements.
                </motion.p>
                <motion.p
                  animate={{ opacity: 1, transition: { duration: 1.5, delay: 0.7 } }}
                  initial={{ opacity: 0 }}
                >
                  I believe in building long-term relationships with my clients and providing
                  ongoing support to ensure their success. If you have a project in mind or are
                  looking to revamp your online presence, I would love to work with you. Please
                  don't hesitate to get in touch with us to discuss your project in more detail.
                </motion.p>
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="contact-form-box">
              <h3 className="title">Get a free quote now</h3>
              <FormOne />
            </div>
          </div>
        </div>
      </div>
      <ul className="shape-group-6 list-unstyled">
        <li className="shape shape-1">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="Bubble" />
        </li>
        <li className="shape shape-2">
          <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" />
        </li>
        <li className="shape shape-3">
          <img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" />
        </li>
      </ul>
    </section>
  );
};

export default AboutOne;
