import React from "react";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaInstagram, FaTwitter, FaLinkedinIn, FaGithub, FaPhone, FaFax } from "react-icons/fa";

const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
  return (
    <Offcanvas
      show={offcanvasShow}
      onHide={offcanvasHide}
      placement="end"
      className="header-offcanvasmenu"
    >
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        {/* <form action="#" className="side-nav-search-form">
                    <div className="form-group">
                        <input type="text" className="search-field" name="search-field" placeholder="Search..." />
                        <button className="side-nav-search-btn"><i className="fas fa-search"></i></button>
                    </div>
                </form> */}
        <div className="row ">
          <div className="col-lg-5 col-xl-6">
            <ul className="main-navigation list-unstyled">
              <li>
                <Link to={process.env.PUBLIC_URL + "/"}>Home</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/services"}>Services</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/projects"}>Projects</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/mentorship"}>Mentorship</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/blogpost"}>Blogs</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-7 col-xl-6">
            <div className="contact-info-wrap">
              <div className="contact-inner">
                <address className="address">
                  <span className="title">Contact Information</span>
                  <p>
                    Email: contact@ajiteshsarangi.in <br />
                  </p>
                </address>
                <address className="address">
                  <span className="title">
                    I'm always there when you need me. Always remember that.
                  </span>
                  {/* <a href="tel:7788988652" className="tel">
                    <FaPhone /> (91) 778-898-8652
                  </a> */}
                  {/* <a href="tel:12125553333" className="tel">
                    <FaFax /> (121) 255-53333
                  </a> */}
                </address>
              </div>
              <div className="contact-inner">
                <h5 className="title">Find us here</h5>
                <div className="contact-social-share">
                  <ul className="social-share list-unstyled">
                    <li>
                      <a href="https://www.instagram.com/ajitesh.sarangi/">
                        <FaInstagram />
                      </a>
                    </li>

                    <li>
                      <a href="https://twitter.com/sarangi_ajitesh">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="https://github.com/ajiteshsarangi">
                        <FaGithub />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/ajitesh-sarangi-9a41a1219/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasMenu;
