import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BlogData from "./data/blog/BlogData.json";
import { slugify } from "./utils";
import { FaPlay, FaAngleRight, FaAngleLeft, FaArrowRight, FaArrowLeft } from "react-icons/fa";
import FsLightbox from "fslightbox-react";
import Slider from "react-slick";
import ReactPaginate from "react-paginate";
import sanityClient from "./client.js";
import imageUrlBuilder from "@sanity/image-url";
import PortableText from "react-portable-text";
// const allBlogData = BlogData;
const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}
const BlogGridOne = ({ searchTerm }) => {
  // sanity ----
  const [postData, setPostData] = useState([]);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="post"]{
        title,
        slug,
        content,
        mainImage{
                asset->{
                    _id,
                    url
                }
            },
        "name": author->name,
        "authorImage": author->image,
        publishedAt
    }`
      )
      .then((data) => {
        setPostData(data);
      })
      .catch(console.error);
  }, []);
  // sanity ends ----
  // date and time -----------
  let [date, time] = [];
  postData.map((e) => {
    [date, time] = e.publishedAt.split("T");
  });

  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }
  // date and time/ ----------
  const [toggler, setToggler] = useState(false);

  function SlickNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaAngleRight />
      </div>
    );
  }

  function SlickPrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaAngleLeft />
      </div>
    );
  }

  var slideSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
  };

  const [blogs] = useState(postData);
  const [pageNumber, setPageNumber] = useState(0);

  const blogsPerPage = 4;
  const pageVisited = pageNumber * blogsPerPage;

  const filteredPosts = postData.filter((post) =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(postData.length / blogsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  // change timezone
  const chnz = (s) => {
    return new Date(s).toLocaleString(undefined, {
      timeZone: "Asia/Kolkata",
    });
  };
  return (
    <>
      {filteredPosts &&
        filteredPosts.slice(pageVisited, pageVisited + blogsPerPage).map((data) => (
          <div className="blog-grid" key={data.title}>
            <h3 className="title">
              <a href="#">{data.title}</a>
            </h3>
            <div className="author">
              <div className="author-thumb">
                <img
                  style={{ height: "100px", borderRadius: " 50%" }}
                  src={urlFor(data.authorImage).url()}
                  alt="Blog Author"
                />
              </div>
              <div className="info">
                <h6 className="author-name">
                  <div>{data.name}</div>
                </h6>
                <ul className="blog-meta list-unstyled">
                  <li>{data.publishedAt.slice(0, 10)}</li>
                  <li>{chnz(data.publishedAt).slice(9, 26)}</li>
                  {/* <li>{tConvert(time.slice(0, 5))}</li> */}
                </ul>
              </div>
            </div>

            <div className="post-thumbnail">
              <Slider className="slick-arrow-nav">
                <div className="slide-item">
                  <img src={urlFor(data.mainImage.asset.url).url()} alt="Blog" />
                </div>
              </Slider>
            </div>
            <p
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                webkitLineClamp: "5",
                webkitBoxOrient: "vertical",
                height: "100px",
              }}
            >
              <PortableText
                content={data.content}
                projectId="u91eo98w"
                dataset="production"
                serializers={{
                  h1: (props) => <h1 style={{ color: "red" }} {...props} />,
                  li: ({ children }) => <li className="special-list-item">{children}</li>,
                }}
              />
            </p>

            <Link
              className="axil-btn btn-borderd btn-large"
              to={process.env.PUBLIC_URL + "/blogpost/" + data.slug.current}
              key={data.slug.current}
              // to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}
            >
              Read More
            </Link>
          </div>
        ))}

      <ReactPaginate
        previousLabel={<FaArrowLeft />}
        nextLabel={<FaArrowRight />}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"pagination justify-content-start"}
        previousLinkClassName={"prev"}
        nextLinkClassName={"next"}
        disabledClassName={"disabled"}
        activeClassName={"current"}
      />
    </>
  );
};

export default BlogGridOne;
