import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaPinterestP,
  FaLinkedin,
  FaInstagram,
  FaGithub,
  FaDribbble,
  FaBehance,
  FaEnvelopeOpen,
} from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from "../../utils";

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-top">
          <div className="footer-social-link">
            <ul className="list-unstyled">
              <li>
                <a href="https://www.facebook.com/ajitesh.sarangi.3">
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/sarangi_ajitesh">
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a href="https://in.pinterest.com/ajiteshsarangi2002/">
                  <FaPinterestP />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/ajitesh-sarangi-9a41a1219/">
                  <FaLinkedin />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/ajitesh.sarangi/">
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a href="https://github.com/ajiteshsarangi">
                  <FaGithub />
                </a>
              </li>
              <li>
                <a href="https://dribbble.com/ajitesh_sarangi">
                  <FaDribbble />
                </a>
              </li>
              <li>
                <a href="https://www.behance.net/ajiteshsarangi">
                  <FaBehance />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                  <h2 className="title">Get in touch!</h2>
                  <p>
                    I believe in building long-term relationships with my clients and providing
                    ongoing support to ensure their success. If you have a project in mind or are
                    looking to revamp your online presence, I would love to work with you. Please
                    don't hesitate to get in touch with us to discuss your project in more detail.
                  </p>
                  <div className="madewith" style={{ alignItems: "center" }}>
                    <p
                      style={{
                        margin: "auto",
                        // marginLeft: "0px",
                        fontSize: "25px",
                        fontWeight: "600",
                        letterSpacing: "1.2px",
                      }}
                    >
                      MADE WITH{" "}
                    </p>
                    <img
                      className="madewith1"
                      style={{
                        height: "50px",
                      }}
                      src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg"
                      alt=""
                    />
                    <img
                      className="madewith1"
                      style={{
                        height: "50px",
                      }}
                      src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg"
                      alt=""
                    />
                    <img
                      className="madewith2"
                      style={{
                        height: "50px",
                      }}
                      src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg"
                      alt=""
                    />
                    <img
                      style={{ height: "50px", width: "160px" }}
                      src="/images/icon/sanity.svg"
                      alt=""
                    />
                  </div>

                  {/* <form>
                                        <div className="input-group">
                                            <span className="mail-icon"><FaEnvelopeOpen /> </span>
                                            <input type="email" className="form-control" placeholder="Email address" />
                                            <button className="subscribe-btn" type="submit">Subscribe</button>
                                        </div>
                                    </form> */}
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="row">
                <div className="col-sm-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">Services</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        {getServiceData.slice(0, 6).map((data, index) => (
                          <li key={index}>
                            <a>{data.title}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">Resourses</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/blogpost"}>Blog</Link>
                        </li>
                        <li>
                          {" "}
                          <Link to={process.env.PUBLIC_URL + "/projects"}>Projects</Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/services"}>Services</Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">Support</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/terms-of-use"}>Terms of Use</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. Made by <strong>Ajitesh Sarangi</strong>. All rights
                  reserved.
                </span>
              </div>
            </div>

            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/terms-of-use"}>Terms of Use</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
