import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectFive from "../component/project/ProjectFive";

const ProjectGridFour = () => {
  return (
    <>
      <SEO title="Project Full Width Three Column" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title="My Projects"
          paragraph="I am excited to share with you a selection of my recent design projects. Each project showcases my ability to understand client needs, conduct user research, and translate findings into intuitive and visually appealing designs."
          styleClass=""
          mainThumb="/images/banner/undraw_projections_re_ulc6.svg"
        />
        <ProjectFive />
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ProjectGridFour;
