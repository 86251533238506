import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "./client.js";
import FooterOne from "./common/footer/FooterOne";
import HeaderOne from "./common/header/HeaderOne";
import CtaLayoutOne from "./component/cta/CtaLayoutOne";
import BlogData from "./data/blog/BlogData.json";
import BreadCrumbOne from "./elements/breadcrumb/BreadCrumbOne";
import BlogSidebar from "./component/blog/BlogSidebar";
import BlogAuthor from "./component/blog/BlogAuthor";
import Comment from "./component/blog/Comment";
import ColorSwitcher from "./elements/switcher/ColorSwitcher";
import SEO from "./common/SEO";
import { FaPlay, FaAngleRight, FaAngleLeft } from "react-icons/fa";
import FsLightbox from "fslightbox-react";
import Slider from "react-slick";
import BlogListOne from "./component/blog/BlogListOne";
import imageUrlBuilder from "@sanity/image-url";
import PortableText from "react-portable-text";
import { Dna } from "react-loader-spinner";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}
const allBlogData = BlogData;

const BlogDetails = () => {
  const params = useParams();
  const blogId = parseInt(params.id);

  const getBlogData = allBlogData.filter((blog) => blog.id === blogId);
  const singlePost = getBlogData[0];

  const [toggler, setToggler] = useState(false);

  function SlickNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaAngleRight />
      </div>
    );
  }

  function SlickPrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaAngleLeft />
      </div>
    );
  }

  var slideSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
  };
  // sanity ------
  const [singleBlogPost, setSinglePost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
            title,
            _id,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            content,
            "name": author->name,
            "authorImage": author->image,
            publishedAt
        }`
      )
      .then((data) => setSinglePost(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!singleBlogPost)
    return (
      <div style={{ textAlign: "center", marginTop: "50%" }}>
        <Dna
          visible={true}
          height="200"
          width="100"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  // sanity ------
  // date and time -----------
  const [date, time] = singleBlogPost.publishedAt.split("T");

  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }
  // change timezone
  const chnz = (s) => {
    return new Date(s).toLocaleString(undefined, {
      timeZone: "Asia/Kolkata",
    });
  };

  return (
    <>
      <SEO title={singleBlogPost.title} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title={singleBlogPost.title} page="Blog" />
        <div className="section-padding-equal">
          <div className="container">
            <div className="row row-40">
              <div className="col-lg-8">
                <div className="single-blog">
                  <div className="single-blog-content blog-grid">
                    <div className="post-thumbnail">
                      <Slider className="slick-arrow-nav">
                        <div className="slide-item">
                          <img src={singleBlogPost.mainImage.asset.url} alt="Blog" />
                        </div>
                      </Slider>
                    </div>

                    <div className="author">
                      <img
                        style={{ height: "75px", borderRadius: "50%" }}
                        src={urlFor(singleBlogPost.authorImage).url()}
                        alt="Blog Author"
                      />

                      <div className="info">
                        <h6 className="author-name">{singleBlogPost.name}</h6>
                        <ul className="blog-meta list-unstyled">
                          <li>{singleBlogPost.publishedAt.slice(0, 10)}</li>
                          <li>{chnz(singleBlogPost.publishedAt).slice(9, 26)}</li>
                        </ul>
                      </div>
                    </div>

                    <div>
                      {" "}
                      <PortableText
                        content={singleBlogPost.content}
                        projectId="u91eo98w"
                        dataset="production"
                        serializers={{
                          h1: (props) => <h1 style={{ color: "red" }} {...props} />,
                          li: ({ children }) => <li className="special-list-item">{children}</li>,
                        }}
                      />
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <div className="featured-img">
                          <img src="/images/blog/blog-8.png" alt="Blog" />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="featured-img">
                          <img src="/images/blog/blog-9.png" alt="Blog" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <BlogAuthor />
              </div>
              <div className="col-lg-4">
                <BlogSidebar />
              </div>
            </div>
          </div>
        </div>

        <div className="section section-padding-equal pt-0 related-blog-area">
          <div className="container">
            <div className="section-heading heading-left">
              <h3 className="title">Related Post</h3>
            </div>
            <div className="row g-0">
              <BlogListOne colSize="col-xl-6" itemShow="2" />
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default BlogDetails;
