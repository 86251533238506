import React from "react";
import { Link } from "react-router-dom";
import WidgetPost from "./widget/WidgetPost";
import WidgetCategory from "./widget/WidgetCategory";
import {
  FaSearch,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaGithub,
  FaDribbble,
} from "react-icons/fa";

const BlogSidebar = () => {
  return (
    <div className="axil-sidebar">
      {/* <div className="widget widget-search">
                <h4 className="widget-title">Search</h4>
                <form action="#" className="blog-search">
                    <input type="text" placeholder="Search…" />
                    <button className="search-button"><FaSearch /></button>
                </form>
            </div> */}
      {/* <div className="widget widget-categories">
        <h4 className="widget-title">Categories</h4>
        <WidgetCategory />
      </div> */}
      <div className="widget widge-social-share">
        <div className="blog-share">
          <h5 className="title">Follow:</h5>
          <ul className="social-list list-unstyled">
            <li>
              <a href="https://www.facebook.com/ajitesh.sarangi.3">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/sarangi_ajitesh">
                <FaTwitter />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/ajitesh.sarangi/">
                <FaInstagram />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/ajitesh-sarangi-9a41a1219/">
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a href="https://github.com/ajiteshsarangi">
                <FaGithub />
              </a>
            </li>
            <li>
              <a href="https://dribbble.com/ajitesh_sarangi">
                <FaDribbble />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="widget widget-recent-post">
        <h4 className="widget-title">Recent post</h4>
        <WidgetPost />
      </div>
    </div>
  );
};

export default BlogSidebar;
