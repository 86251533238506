import React, { useState, useEffect } from "react";
import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import PortableText from "react-portable-text";
import { useParams } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaPinterestP,
  FaLinkedin,
  FaInstagram,
  FaVimeoV,
  FaDribbble,
  FaBehance,
} from "react-icons/fa";
const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}
const BlogAuthor = () => {
  // sanity ------
  const [Post, setSinglePost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
            title,
            _id,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            content,
            "name": author->name,
            "authorImage": author->image,
            "bio":author->bio
        }`
      )
      .then((data) => setSinglePost(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!Post) return <div>Loading...</div>;
  console.log(Post.bio);
  // sanity ------
  return (
    <div className="blog-author">
      <div className="author">
        <div className="author-thumb">
          <img src={urlFor(Post.authorImage).url()} alt="Blog Author" />
        </div>
        <div className="info">
          <h5 className="title">{Post.name}</h5>
          <p>
            <PortableText
              content={Post.bio}
              projectId="u91eo98w"
              dataset="production"
              serializers={{
                h1: (props) => <h1 style={{ color: "red" }} {...props} />,
                li: ({ children }) => <li className="special-list-item">{children}</li>,
              }}
            />
          </p>
          {/* <ul className="social-share list-unstyled">
            <li>
              <a href="#">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="#">
                <FaTwitter />
              </a>
            </li>
            <li>
              <a href="#">
                <FaLinkedin />
              </a>
            </li>
            <li>
              <a href="#">
                <FaInstagram />
              </a>
            </li>
            <li>
              <a href="#">
                <FaVimeoV />
              </a>
            </li>
            <li>
              <a href="#">
                <FaPinterestP />
              </a>
            </li>
            <li>
              <a href="#">
                <FaDribbble />
              </a>
            </li>
            <li>
              <a href="#">
                <FaBehance />
              </a>
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  );
};

export default BlogAuthor;
